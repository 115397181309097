import {
	GET_COMPANIES_REQUEST,
	GET_COMPANIES_SUCCESS,
	GET_COMPANIES_FAILURET,
	GET_Jug_REQUEST,
	GET_Jug_SUCCESS,
	GET_Jug_FAILURET,
	POST_Cat_REQUEST,
	POST_Cat_SUCCESS,
	POST_Cat_FAILURET,
	GET_TITLE_REQUEST,
	GET_TITLE_SUCCESS,
	GET_TITLE_FAILURET,
	GET_CALENDARIOTORNEOSACTIVOS_REQUEST,
	GET_CALENDARIOTORNEOSACTIVOS_SUCCESS,
	GET_CALENDARIOTORNEOSACTIVOS_FAILURET,
	GET_CLASIFICACION_REQUEST,
	GET_CLASIFICACION_SUCCESS,
	GET_CLASIFICACION_FAILURET,
	GET_GOLEADORESTORNEOSACTIVOS_REQUEST,
	GET_GOLEADORESTORNEOSACTIVOS_SUCCESS,
	GET_GOLEADORESTORNEOSACTIVOS_FAILURET,
	GET_ESTADISTICAJUGADORTORNEO_REQUEST,
	GET_ESTADISTICAJUGADORTORNEO_SUCCESS,
	GET_ESTADISTICAJUGADORTORNEO_FAILURET,
	GET_JUGADORESLIDERESTORNEO_REQUEST,
	GET_JUGADORESLIDERESTORNEO_SUCCESS,
	GET_JUGADORESLIDERESTORNEO_FAILURET,
	GET_RESUMENPARTIDO_REQUEST,
	GET_RESUMENPARTIDO_SUCCESS,
	GET_RESUMENPARTIDO_FAILURET,
	GET_EDICIONESTORNEO_REQUEST,
	GET_EDICIONESTORNEO_SUCCESS,
	GET_EDICIONESTORNEO_FAILURET,
	GET_TORNEOACTIVO_REQUEST,
	GET_TORNEOACTIVO_SUCCESS,
	GET_TORNEOACTIVO_FAILURET,
} from '../actions/example.actions';

const Example = (state = {}, action) => {
	switch (action.type) {
		case GET_COMPANIES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_COMPANIES_SUCCESS:
			return {
				...state,
				loading: false,
				hola: action.payload.hola,
			};
		case GET_COMPANIES_FAILURET:
			return {
				...state,
				loading: false,
				error: true,
			};
		
		case GET_TORNEOACTIVO_REQUEST:
			return {
				...state,
				loading_TORNEOACTIVO: true,
			};
		case GET_TORNEOACTIVO_SUCCESS:
			return {
				...state,
				loading_TORNEOACTIVO: false,
				TORNEOACTIVO: action.payload.hola,
			};
		case GET_TORNEOACTIVO_FAILURET:
			return {
				...state,
				loading_TORNEOACTIVO: false,
				error: true,
			};

		//HEADER MENU TITLE
		case GET_TITLE_REQUEST:
			return {
				...state,
				loading_title: true,
			};
		case GET_TITLE_SUCCESS:
			return {
				...state,
				loading_title: false,
				title: action.payload.title,
			};
		case GET_TITLE_FAILURET:
			return {
				...state,
				loading_title: false,
				error_title: true,
			};

		case GET_CALENDARIOTORNEOSACTIVOS_REQUEST:
			return {
				...state,
				loading_CALENDARIOTORNEOSACTIVOS: true,
			};
		case GET_CALENDARIOTORNEOSACTIVOS_SUCCESS:
			return {
				...state,
				loading_CALENDARIOTORNEOSACTIVOS: false,
				CALENDARIOTORNEOSACTIVOS: action.payload.calendario,
			};
		case GET_CALENDARIOTORNEOSACTIVOS_FAILURET:
			return {
				...state,
				loading_CALENDARIOTORNEOSACTIVOS: false,
				error_CALENDARIOTORNEOSACTIVOS: true,
			};

		case GET_ESTADISTICAJUGADORTORNEO_REQUEST:
			return {
				...state,
				loading_ESTADISTICAJUGADORTORNEO: true,
				SHOWMODAL: false,
			};
		case GET_ESTADISTICAJUGADORTORNEO_SUCCESS:
			return {
				...state,
				loading_ESTADISTICAJUGADORTORNEO: false,
				ESTADISTICAJUGADORTORNEO: action.payload.estadistica,
				SHOWMODAL: true,
			};
		case GET_ESTADISTICAJUGADORTORNEO_FAILURET:
			return {
				...state,
				loading_ESTADISTICAJUGADORTORNEO: false,
				error_ESTADISTICAJUGADORTORNEO: true,
				SHOWMODAL: false,
			};

		case GET_CLASIFICACION_REQUEST:
			return {
				...state,
				loading_CLASIFICACION: true,
			};
		case GET_CLASIFICACION_SUCCESS:
			return {
				...state,
				loading_CLASIFICACION: false,
				CLASIFICACION: action.payload.clasificacion,
			};
		case GET_CLASIFICACION_FAILURET:
			return {
				...state,
				loading_CLASIFICACION: false,
				error_CLASIFICACION: true,
			};

		case GET_GOLEADORESTORNEOSACTIVOS_REQUEST:
			return {
				...state,
				loading_GOLEADORESTORNEOSACTIVOS: true,
			};
		case GET_GOLEADORESTORNEOSACTIVOS_SUCCESS:
			return {
				...state,
				loading_GOLEADORESTORNEOSACTIVOS: false,
				GOLEADORESTORNEOSACTIVOS: action.payload.goleadores,
			};
		case GET_GOLEADORESTORNEOSACTIVOS_FAILURET:
			return {
				...state,
				loading_GOLEADORESTORNEOSACTIVOS: false,
				error_GOLEADORESTORNEOSACTIVOS: true,
			};

		case GET_JUGADORESLIDERESTORNEO_REQUEST:
			return {
				...state,
				loading_JUGADORESLIDERESTORNEO: true,
			};
		case GET_JUGADORESLIDERESTORNEO_SUCCESS:
			return {
				...state,
				loading_JUGADORESLIDERESTORNEO: false,
				JUGADORESLIDERESTORNEO: action.payload.lideres,
			};
		case GET_JUGADORESLIDERESTORNEO_FAILURET:
			return {
				...state,
				loading_JUGADORESLIDERESTORNEO: false,
				error_JUGADORESLIDERESTORNEO: true,
			};

		case GET_RESUMENPARTIDO_REQUEST:
			return {
				...state,
				loading_RESUMENPARTIDO: true,
				SHOWMODALRESUMEN: false,
			};
		case GET_RESUMENPARTIDO_SUCCESS:
			return {
				...state,
				loading_RESUMENPARTIDO: false,
				RESUMENPARTIDO: action.payload.resumen,
				SHOWMODALRESUMEN: true,
			};
		case GET_RESUMENPARTIDO_FAILURET:
			return {
				...state,
				loading_RESUMENPARTIDO: false,
				error_RESUMENPARTIDO: true,
				SHOWMODALRESUMEN: false,
			};
			case GET_EDICIONESTORNEO_REQUEST:
				return {
					...state,
					loading_EDICIONESTORNEO: true,
					EDICIONESTORNEO: false,
				};
			case GET_EDICIONESTORNEO_SUCCESS:
				return {
					...state,
					loading_EDICIONESTORNEO: false,
					EDICIONESTORNEO: action.payload.ediciones
				};
			case GET_EDICIONESTORNEO_FAILURET:
				return {
					...state,
					loading_EDICIONESTORNEO: false,
					error_EDICIONESTORNEO: true
				};

		default:
			return {
				...state,
			};
	}
};
export default Example;
