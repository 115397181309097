import axios from 'axios';

function getAutho() {
	try {
		let result = sessionStorage.getItem('data')
			? JSON.parse(sessionStorage.getItem('data')).token
			: '';
		return result;
	} catch (error) {
		return '';
	}
}

const axiosGW = axios.create({
	baseURL: process.env.REACT_APP_AWS_API_GATEWAY_U,
	
});

const baseService = {
	GW: {
		get: (url, params) => axiosGW.get(url, params),
		post: (url, data, params) => axiosGW.post(url, data, params),
		put: (url, data, params) => axiosGW.put(url, data, params),
		delete: (url, params) => axiosGW.delete(url, params),
		all: (requests) => axios.all(requests).then((results) => results.map((r) => r.data)),
	},
	
};
export default baseService;
