import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';

// import './assets/metronic/plugins/global/plugins.bundle.css';
// import './assets/metronic/plugins/custom/prismjs/prismjs.bundle.css';
import './assets/metronic/css/style.bundle.css';

// import './App.css';
import reportWebVitals from './reportWebVitals';
import serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Loading from './components/loading.js';
// import Home from './components/torneo';
const App = React.lazy(() => import('./App.js'));
const Home = React.lazy(() => import('./components/torneo'));
const Torneo = React.lazy(() => import('./components/content/pages/torneo'));
const SearchSlider = React.lazy(() => import('./components/content/searchSlider/search'));
const Summary = React.lazy(() => import('./components/content/summary/summary'));
const CardFantazyOrQuiniela = React.lazy(() => import('./components/content/atoms/cardFantazyOrQuiniela'));
const News = React.lazy(() => import('./components/content/news/news'));
const CalendarTab = React.lazy(() => import('./components/calendar/calendarTab'));
const hist = createBrowserHistory();


ReactDOM.render(
	<Provider store={store}>
		<Router history={hist}>
			<Suspense fallback={<Loading />}>				
				<Switch>
					<Route path='/' exact component={App} />
					<Route path='/resumen-goleadores-torneo/:idTorneo' exact component={App} />
					<Route path='/list-torneos' exact component={App} />
					<Route path={'/torneo/:idTorneo/:idEdicion'} exact component={Home}/>				
				</Switch>				
			</Suspense>
		</Router>
	</Provider>,
	document.getElementById('root')
);
serviceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
