import axios from 'axios';
import baseService from '../utils/baseService';
import {host_api} from '../utils/config';

export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILURET = 'GET_COMPANIES_FAILURET';

export const GET_Jug_REQUEST = 'GET_Jug_REQUEST';
export const GET_Jug_SUCCESS = 'GET_Jug_SUCCESS';
export const GET_Jug_FAILURET = 'GET_Jug_FAILURET';

export const POST_Cat_REQUEST = 'POST_Cat_REQUEST';
export const POST_Cat_SUCCESS = 'POST_Cat_SUCCESS';
export const POST_Cat_FAILURET = 'POST_Cat_FAILURET';

export const GET_TITLE_REQUEST = 'GET_TITLE_REQUEST';
export const GET_TITLE_SUCCESS = 'GET_TITLE_SUCCESS';
export const GET_TITLE_FAILURET = 'GET_TITLE_FAILURET';

export const GET_CALENDARIOTORNEOSACTIVOS_REQUEST = 'GET_CALENDARIOTORNEOSACTIVOS_REQUEST';
export const GET_CALENDARIOTORNEOSACTIVOS_SUCCESS = 'GET_CALENDARIOTORNEOSACTIVOS_SUCCESS';
export const GET_CALENDARIOTORNEOSACTIVOS_FAILURET = 'GET_CALENDARIOTORNEOSACTIVOS_FAILURET';

export const GET_CLASIFICACION_REQUEST = 'GET_CLASIFICACION_REQUEST';
export const GET_CLASIFICACION_SUCCESS = 'GET_CLASIFICACION_SUCCESS';
export const GET_CLASIFICACION_FAILURET = 'GET_CLASIFICACION_FAILURET';

export const GET_GOLEADORESTORNEOSACTIVOS_REQUEST = 'GET_GOLEADORESTORNEOSACTIVOS_REQUEST';
export const GET_GOLEADORESTORNEOSACTIVOS_SUCCESS = 'GET_GOLEADORESTORNEOSACTIVOS_SUCCESS';
export const GET_GOLEADORESTORNEOSACTIVOS_FAILURET = 'GET_GOLEADORESTORNEOSACTIVOS_FAILURET';

export const GET_ESTADISTICAJUGADORTORNEO_REQUEST = 'GET_ESTADISTICAJUGADORTORNEO_REQUEST';
export const GET_ESTADISTICAJUGADORTORNEO_SUCCESS = 'GET_ESTADISTICAJUGADORTORNEO_SUCCESS';
export const GET_ESTADISTICAJUGADORTORNEO_FAILURET = 'GET_ESTADISTICAJUGADORTORNEO_FAILURET';

export const GET_JUGADORESLIDERESTORNEO_REQUEST = 'GET_JUGADORESLIDERESTORNEO_REQUEST';
export const GET_JUGADORESLIDERESTORNEO_SUCCESS = 'GET_JUGADORESLIDERESTORNEO_SUCCESS';
export const GET_JUGADORESLIDERESTORNEO_FAILURET = 'GET_JUGADORESLIDERESTORNEO_FAILURET';

export const GET_RESUMENPARTIDO_REQUEST = 'GET_RESUMENPARTIDO_REQUEST';
export const GET_RESUMENPARTIDO_SUCCESS = 'GET_RESUMENPARTIDO_SUCCESS';
export const GET_RESUMENPARTIDO_FAILURET = 'GET_RESUMENPARTIDO_FAILURET';

export const GET_EDICIONESTORNEO_REQUEST = 'GET_EDICIONESTORNEO_REQUEST';
export const GET_EDICIONESTORNEO_SUCCESS = 'GET_EDICIONESTORNEO_SUCCESS';
export const GET_EDICIONESTORNEO_FAILURET = 'GET_EDICIONESTORNEO_FAILURET';

export const GET_TORNEOACTIVO_REQUEST = 'GET_TORNEOACTIVO_REQUEST';
export const GET_TORNEOACTIVO_SUCCESS = 'GET_TORNEOACTIVO_SUCCESS';
export const GET_TORNEOACTIVO_FAILURET = 'GET_TORNEOACTIVO_FAILURET';


export const getTitleHeaderMenu = () => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/header-menu').then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_TITLE_REQUEST };
	}

	function success(data) {
		return { type: GET_TITLE_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_TITLE_FAILURET, payload: error };
	}
};

export const getClasificacion = (idTorneo,idEdicion) => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/clasificacion/'+idTorneo+'/'+idEdicion).then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_CLASIFICACION_REQUEST };
	}

	function success(data) {
		return { type: GET_CLASIFICACION_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_CLASIFICACION_FAILURET, payload: error };
	}
};

export const getResumenPartido = (idPartido,idEquipoLocal,idEquipoVisit)=>{
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/resumen-partido/'+idPartido+'/'+idEquipoLocal+'/'+idEquipoVisit).then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_RESUMENPARTIDO_REQUEST };
	}

	function success(data) {
		return { type: GET_RESUMENPARTIDO_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_RESUMENPARTIDO_FAILURET, payload: error };
	}
}

export const getCalendarioTorneosActivos = (idTorneo,idEdicion,idJornada) => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/calendario/'+idTorneo+'/'+idEdicion+'/'+idJornada).then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_CALENDARIOTORNEOSACTIVOS_REQUEST };
	}

	function success(data) {
		return { type: GET_CALENDARIOTORNEOSACTIVOS_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_CALENDARIOTORNEOSACTIVOS_FAILURET, payload: error };
	}
};

export const getEstadisticaJugadorTorneo = (idTorneo,idEdicion,idContrato,idJugador) => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/estadistica-jugador/'+idTorneo+'/'+idEdicion+'/'+idContrato+'/'+idJugador).then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_ESTADISTICAJUGADORTORNEO_REQUEST };
	}

	function success(data) {
		return { type: GET_ESTADISTICAJUGADORTORNEO_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_ESTADISTICAJUGADORTORNEO_FAILURET, payload: error };
	}
};

export const getGoleadoresTorneosActivos = (idTorneo,idEdicion) => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/resumen-goleadores/'+idTorneo+'/'+idEdicion).then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_GOLEADORESTORNEOSACTIVOS_REQUEST };
	}

	function success(data) {
		return { type: GET_GOLEADORESTORNEOSACTIVOS_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_GOLEADORESTORNEOSACTIVOS_FAILURET, payload: error };
	}
};

export const getJugadoresLideresTorneos = (idTorneo, idEdicion,limit) => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/jugadores-lideres/'+idTorneo+'/'+idEdicion+"/"+limit).then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_JUGADORESLIDERESTORNEO_REQUEST };
	}

	function success(data) {
		return { type: GET_JUGADORESLIDERESTORNEO_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_JUGADORESLIDERESTORNEO_FAILURET, payload: error };
	}
};

export const getEdicionesTorneo = (nombreTorneo) => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/ediciones-torneos/'+nombreTorneo).then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_EDICIONESTORNEO_REQUEST };
	}

	function success(data) {
		return { type: GET_EDICIONESTORNEO_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_EDICIONESTORNEO_FAILURET, payload: error };
	}
};

export const getTorneosActivos = (idTorneo, idEdicion) => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/torneos/'+idTorneo+'/'+idEdicion).then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_TORNEOACTIVO_REQUEST };
	}

	function success(data) {
		return { type: GET_TORNEOACTIVO_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_TORNEOACTIVO_FAILURET, payload: error };
	}
};

export const getTest = () => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/api/torneos').then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_COMPANIES_REQUEST };
	}

	function success(data) {
		return { type: GET_COMPANIES_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_COMPANIES_FAILURET, payload: error };
	}
};

export const getTest2 = () => {
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.get(host_api+'/jugadores').then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: GET_Jug_REQUEST };
	}

	function success(data) {
		return { type: GET_Jug_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: GET_Jug_FAILURET, payload: error };
	}
};

export const getCategorias = (param) => {
	console.log(param, 'dimelo');
	return async (dispatch, getState) => {
		dispatch(request());
		// baseService.GW.get('http://api.eolietur.it/api/test').then(
		await baseService.GW.post(host_api+'/api/categorias', param).then(
			(data) => {
				dispatch(success(data.data));
			},
			(error) => {
				dispatch(failure(error.toString()));
			}
		);
	};

	function request() {
		return { type: POST_Cat_REQUEST };
	}

	function success(data) {
		return { type: POST_Cat_SUCCESS, payload: data };
	}

	function failure(error) {
		return { type: POST_Cat_FAILURET, payload: error };
	}
};


export const guardarLogVisitaDB = () => {
	
	return async (dispatch, getState) => {		
		await baseService.GW.get(host_api+'/api/guardarLogVisita').then(
			(data) => {
				console.log(data.data);
			},
			(error) => {
				console.log("error");
			}
		);
	};	
};
// dispatch(request());
// baseService.GW.post('?filter', data).then(
// 	(data) => {
// 		dispatch(success(data.data));
// 	},
// 	(error) => {
// 		dispatch(failure(error.toString()));
// 		manageError(error, dispatch);
// 	}
// );

// asyncrono
// export const sessionLogout = (employeId, idAddress) => {
// 	return async dispatch => {
// 	  const requestModel = { "employeeId": employeId, "ipAddress": idAddress }
// 	  const url = `api/logout?employeeId=${encodeURIComponent(employeId)}&ipAddress=${idAddress}`

// 	  try {
// 		await postNoJsonRequest(url, requestModel)
// 		dispatch({
// 		  type: 'SESSION_LOGOUT'
// 		})
// 	  } catch (error) {
// 		dispatch({
// 		  type: 'SESSION_LOGOUT'
// 		})
// 	  }
// 	}
//   }
